@use "@angular/material" as mat;

@include mat.core();

$primary: mat.define-palette(mat.$indigo-palette);
$accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$theme: mat.define-light-theme(
	(
		color: (
			primary: $primary,
			accent: $accent
		),
		typography:
			mat.define-typography-config(
				$font-family: "Roboto"
			),
		density: 0
	)
);

@include mat.all-component-themes($theme);

@include mat.typography-hierarchy($theme);

html {
	overflow-y: scroll;
}

mat-card-content {
	position: relative;
}
